import {Sortable as Library} from 'sortablejs';
import axios from 'axios';

const Sortable = function () {
    const init = function () {
        document.querySelectorAll('[data-component="sortable"]').forEach(function (el) {
            sortable(el);
        });
    }

    const sortable = function (element) {
        const options = {
            animation: 150,
            scroll: true,
            onSort: function() {
                const ordering = element.querySelectorAll('[data-id]');
                const list = [];
                const csrf = element.getAttribute('data-csrf');
                const url = element.getAttribute('data-url');
                for (const order of ordering) {
                    list.push(order.getAttribute('data-id'));
                }
                axios.put(url, {order: list}, {withCredentials: true});
            }
        }

        if ( element.hasAttribute('data-handle-class') ) {
            options.handle = '.'+element.getAttribute('data-handle-class');
        }

        new Library(
            element,
            options
        )
    };

    init();
};

KTUtil.onDOMContentLoaded(function () {
    Sortable();
});

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = Sortable;
}
