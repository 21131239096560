import 'chartjs-gauge'

const Gauge = function () {
    const init = function () {
        document.querySelectorAll('[data-component="gauge"]').forEach(function (el) {
            initChart(el);
        });
    }

    const initChart = function (element) {
        const good = element.getAttribute('data-good');
        const max = element.getAttribute('data-max');
        const actual = element.getAttribute('data-actual');

        new Chart(element, {
            type: 'gauge',
            data: {
                datasets: [{
                    value: actual,
                    minValue: 0,
                    data: [good, max],
                    backgroundColor: ['#262971', '#abc509'],
                }]
            },
            options: {
                needle: {
                    radiusPercentage: 2,
                    widthPercentage: 3.2,
                    lengthPercentage: 80,
                    color: 'rgba(38, 41, 113, 1)'
                },
                valueLabel: {
                    display: actual !== '',
                    formatter: (value) => {
                        return value + '%';
                    },
                    color: 'rgba(255, 255, 255, 1)',
                    backgroundColor: 'rgba(38, 41, 113, 1)',
                    borderRadius: 5,
                    padding: {
                        top: 10,
                        bottom: 10
                    }
                }
            }
        });
    };

    init();
};

KTUtil.onDOMContentLoaded(function () {
    Gauge();
});

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = Gauge;
}
