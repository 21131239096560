// Keenthemes' plugins
window.KTComponents = require('@/resources/js/components/_init.js');
window.KTUtil = require('@/resources/js/components/util.js');
window.KTApp = require('@/resources/js/components/app.js');
window.KTEventHandler = require('@/resources/js/components/event-handler.js');
window.KTBlockUI = require('@/resources/js/components/blockui.js');
window.KTCookie = require('@/resources/js/components/cookie.js');
window.KTDialer = require('@/resources/js/components/dialer.js');
window.KTDrawer = require('@/resources/js/components/drawer.js');
window.KTFeedback = require('@/resources/js/components/feedback.js');
window.KTImageInput = require('@/resources/js/components/image-input.js');
window.KTMenu = require('@/resources/js/components/menu.js');
window.KTPasswordMeter = require('@/resources/js/components/password-meter.js');
window.Gauge = require('@/resources/js/components/gauge.js');
window.Sortable = require('@/resources/js/components/sortable.js');
window.DateRangePicker = require('@/resources/js/components/date-range-picker.js');
window.SubscriptionButtons = require('@/resources/js/components/subscription-buttons.js');
window.FilterTypeOptions = require('@/resources/js/components/filter-type-options.js');
window.KTScroll = require('@/resources/js/components/scroll.js');
window.KTScrolltop = require('@/resources/js/components/scrolltop.js');
window.KTSearch = require('@/resources/js/components/search.js');
window.KTStepper = require('@/resources/js/components/stepper.js');
window.KTSticky = require('@/resources/js/components/sticky.js');
window.KTSwapper = require('@/resources/js/components/swapper.js');
window.KTToggle = require('@/resources/js/components/toggle.js');


// Layout base js
window.KTLayoutHeader = require('@/resources/js/layout/header.js');
window.KTLayoutSearch = require('@/resources/js/layout/search.js');
window.KTThemeMode = require('@/resources/js/layout/theme-mode.js');
window.KTThemeModeUser = require('@/resources/js/layout/theme-mode-user.js');
