import flatpickr from "flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl"


const DateRangePicker = function () {
    const init = function () {
        document.querySelectorAll('[data-component="date-range-picker"]').forEach(function (el) {
            initPicker(el);
        });
    }

    const initPicker = function (element) {
        const fp = flatpickr(element, {
            mode: "range",
            locale: Dutch,
        });

        const fromDate = element.dataset.from ?? undefined;
        const toDate = element.dataset.to ?? undefined;

        if ( fromDate !== undefined && toDate !== undefined) {
            fp.setDate([fromDate, toDate], true);
        }
    }

    init();
}

KTUtil.onDOMContentLoaded(function () {
    DateRangePicker();
});

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = DateRangePicker;
}
