const SubscriptionButtons = function() {
    const init = function () {
        window.showCancelSubscriptionBox = showCancelSubscriptionBox;
        window.showReactivateSubscriptionBox = showReactivateSubscriptionBox;
    }

    const showReactivateSubscriptionBox = function (url, csrfToken) {
        swal.fire({
            title: "Abonnement opnieuw activeren",
            text: "We zijn blij dat je opnieuw je abonnement wilt activeren! Je bedrijf is direct zichtbaar in onze app en je abonnementsperiode wordt direct verlengt.",
            icon: "info",
            buttonsStyling: false,
            showDenyButton: true,
            allowEnterKey: true,
            focusConfirm: true,
            focusDeny: false,
            confirmButtonText: "Opnieuw activeren",
            denyButtonText: 'Annuleren',
            customClass: {
                confirmButton: "btn btn-success",
                denyButton: "btn btn-light-danger"
            }
        }).then((result) => {
            if (result.isConfirmed) {

                const xhr = new XMLHttpRequest();
                xhr.open("POST", url, true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onreadystatechange = function () {
                    if (this.readyState !== 4) {
                        return;
                    }

                    if (this.status === 200) {
                        const data = JSON.parse(this.responseText);

                        if (data.success === '0') {
                            fireSomethingWentWrongAlert(data.message);
                        } else {
                            window.location.reload();
                        }
                    } else {
                        fireSomethingWentWrongAlert('Er ging iets mis. Probeer het later opnieuw.');
                    }
                };
                xhr.send(JSON.stringify({
                    _token: csrfToken
                }));
            }
        });
    }

    const showCancelSubscriptionBox = function (cancelUrl, csrfToken) {
        swal.fire({
            title: "Abonnement opzeggen",
            text: "Wat jammer dat je het abonnement wilt opzeggen. Bij het opzeggen van het abonnement wordt je bedrijf nog getoond in de app totdat je huidige abonnementsperiode is afgelopen. Weet je zeker dat je op wilt zeggen?",
            icon: "warning",
            buttonsStyling: false,
            showDenyButton: true,
            inputLabel: 'Reden van opzeggen',
            input: 'textarea',
            allowEnterKey: false,
            focusConfirm: false,
            focusDeny: true,
            confirmButtonText: "Ja, opzeggen",
            denyButtonText: 'Nee, annuleren',
            customClass: {
                confirmButton: "btn btn-light-danger",
                denyButton: "btn btn-primary"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const value = result.value;

                const xhr = new XMLHttpRequest();
                xhr.open("POST", cancelUrl, true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onreadystatechange = function () {
                    if (this.readyState !== 4) {
                        return;
                    }

                    if (this.status === 200) {
                        const data = JSON.parse(this.responseText);

                        if (data.success === '0') {
                            fireSomethingWentWrongAlert(data.message);
                        } else {
                            window.location.reload();
                        }
                    } else {
                        fireSomethingWentWrongAlert('Er ging iets mis. Probeer het later opnieuw.');
                    }
                };
                xhr.send(JSON.stringify({
                    reason: value,
                    _token: csrfToken
                }));
            }
        });
    }

    const fireSomethingWentWrongAlert = function (message) {
        swal.fire({
            title: "Er ging iets mis",
            text: message,
            icon: "error",
            timer: 5000,
            timerProgressBar: true,
        })
    }

    init();
}

KTUtil.onDOMContentLoaded(function () {
    SubscriptionButtons();
});

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = SubscriptionButtons;
}
