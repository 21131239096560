const Options = function () {
    const init = function() {
        const element = document.querySelector('[name="filter_options"]', {});

        if ( !element ) {
            return;
        }

        const options = new Tagify(element);
    }

    init();
}

KTUtil.onDOMContentLoaded(function () {
    Options();
});

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = Options;
}
